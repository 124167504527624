import React, { useEffect, useState } from "react";
import logo from "../../assets/logo.png";
import images from "../../constants/images";
import "./Navbar.css";
import { CiMenuBurger } from "react-icons/ci";
import { IoMdClose } from "react-icons/io";
import { MdDeliveryDining } from "react-icons/md";
import { useNavigate } from "react-router";
import { IoHomeOutline } from "react-icons/io5";
import { FaBowlFood } from "react-icons/fa6";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { RiGalleryFill } from "react-icons/ri";
import { RiContactsLine } from "react-icons/ri";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMdArrowDropdown } from "react-icons/io";
import MenuItem from "../Menuitem/MenuItem";
import { FaHome } from "react-icons/fa";
import { IoMdContact } from "react-icons/io";
import { IoIosInformationCircle } from "react-icons/io";
import { MdShoppingCart } from "react-icons/md";
import CartIcon from "../../container/ThePortions/CartIcon";
import { useDispatch, useSelector } from "react-redux";
import { setInEnglish } from "../../State/State";
import translate from "translate";

const Navbar = () => {
  const [smallScreenOpen, setSmallScreenOpen] = useState(false);
  const inEnglish = useSelector(state => state.inEnglish)
  const dispatch = useDispatch()
  const [dropdownMenu, setDropdownMenu] = useState(false);
  const navigate = useNavigate();
  
  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo logo-akhanda">
        <img
          src={logo}
          onClick={() => navigate("/")}
          style={{ height: "270px", width: "270px" }}
        />
      </div>
      <ul className="app__navbar-links">
        
        <li className="p__opensans" onMouseLeave={() => setDropdownMenu(false)}>
          <div
            className="app__wrapper"
            onClick={() => setDropdownMenu(!dropdownMenu)}
          >
            <adiv style={{ position: "relative" }}>
              <span>Menu</span>
            </adiv>
            <IoMdArrowDropdown
              color="#FF8303"
              size={8}
              style={{ zIndex: "10000" }}
            />
          </div>
          <div
            className={
              dropdownMenu
                ? "app__navabar-lunch-dropdown"
                : "app__navabar-lunch-dropdown-none"
            }
          >
            <div className="p__opensans">
              <a onClick={() => navigate("/lounas")}>{inEnglish?"Lunch":"Lounas"}</a>
            </div>
            <div className="p__opensans">
              <a onClick={() => navigate("/alacarte")}>À la carte</a>
            </div>
          </div>
        </li>
        
        <li className="p__opensans">
          <a hred="" onClick={() => navigate("/gallery")}>
            <span>{inEnglish?"GALLERY":"GALLERIAMME"}</span>
          </a>
        </li>
        <li className="p__opensans">
          <a onClick={() => navigate("/contact")}>
            <span>{inEnglish?"CONTACT US":"YHTEYSTIEDOT"}</span>
          </a>
        </li>
        <li className="p__opensans">
          <a href="https://www.quandoo.fi/fi/checkout-widget/widget?merchantId=103881&primaryColor=fe8301&theme=dark&aid=146&utm_source=quandoo-partner&utm_medium=widget-link" target="_blank">
            <span>{inEnglish?"Table Reservation":"PÖYDÄNVARAUS"}</span>
          </a>
        </li>
        
      </ul>

      <div className="app__navbar-login">
        <div className="app__navbar-cartcontainer">
          <a className="app__navbar-carticon" onClick={() => navigate("/cart")}>
            <span>
              <CartIcon />
            </span>
            <span
              style={{
                fontFamily: "Poppins",
                fontWeight: "600",
              }}
            >
              {inEnglish?"CART":"KORRIN"}
            </span>
          </a>
        </div>
        <div>
          <FaPhoneAlt
            color="#FF8303"
            size={1}
            style={{ height: "30px", width: "30px", marginRight: "18px" }}
          />
        </div>
        <div className="app__navbar-order">
          <p>{inEnglish?"Order Now":"Tilaa nyt"}</p>
          <p>0400225222</p>
        </div>
        <div className="app__navbar_language">
          <a className={inEnglish?"app_navbar_language_circle":""} onClick={() => dispatch(setInEnglish({language:true}))}><img style={{height:"22px",width:"22px"}} src={images.en} /></a>
          <a>|</a>
          <a className={inEnglish?"":"app_navbar_language_circle"} onClick={() => dispatch(setInEnglish({language:false}))}><img  style={{height:"22px"}} src={images.fi} /></a>
        </div>
      </div>

      <div className="app__navbar-smallscreen">
        <CiMenuBurger
          color="#fff"
          className="overlay__close"
          onClick={() => setSmallScreenOpen(true)}
        />
        <div className="app__navbar-logo-smallscreen">
          <img src={logo}  onClick={() => navigate("/")} />
        </div>

        {smallScreenOpen && (
          <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
            <IoMdClose
              size={20}
              color="#FF8303"
              className="overlay__close"
              onClick={() => setSmallScreenOpen(false)}
            />
            <div className="app__navbar-logo-smallscreen">
              <img src={logo}  />
            </div>
            <ul className="app__navbar-smallscreen-links">
              <li className="p__opensans">
                <a className="nav__link" onClick={() => navigate("/")}>
                  <FaHome size={22} color="#FF8303" />
                  {inEnglish?"Home":"KOTI"}
                </a>
              </li>
              <li className="p__opensans">
                <a className="nav__link" onClick={() => navigate("/alacarte")}>
                  <FaBowlFood size={22} color="#FF8303" />À LA CARTE
                </a>
              </li>
              <li className="p__opensans">
                <a className="nav__link" onClick={() => navigate("/cart")}>
                  <MdShoppingCart size={22} color="#FF8303" />
                  {inEnglish?"Cart":"KORRIN"}
                </a>
              </li>
              
              <li className="p__opensans">
                <a className="nav__link" onClick={() => navigate("/gallery")}>
                  <RiGalleryFill size={22} color="#FF8303" />
                  {inEnglish?"Gallery":"GALLERIAMME"}
                </a>
              </li>
              <li className="p__opensans">
                <a className="nav__link" onClick={() => navigate("/contact")}>
                  <IoMdContact size={22} color="#FF8303" />
                  {inEnglish?"CONTACT US":"YHTEYSTIEDOT"}
                </a>
              </li>
              
            </ul>
          </div>
        )}

        <div className="app__navbar-login-smallscreen">
          
          <div className="app__navbar_language">
          <a className={inEnglish?"app_navbar_language_circle":""} onClick={() => dispatch(setInEnglish({language:true}))}><img style={{height:"16px",width:"16px"}} src={images.en} /></a>
          <a>|</a>
          <a className={inEnglish?"":"app_navbar_language_circle"} onClick={() => dispatch(setInEnglish({language:false}))}><img  style={{height:"16px"}} src={images.fi} /></a>
         
        </div>
        </div>
       
      </div>
    </nav>
  );
};

export default Navbar;
