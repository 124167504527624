import React, { useEffect, useState } from 'react';
import './SpecialOfferNotice.css';

const RestaurantNotice = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <div className={`notice-container ${isVisible ? 'visible' : ''}`}>
      <div className="notice-card">
        <div className="notice-content">
          <h1 className="notice-title bounce">Syöviikot.fi Special Offer!</h1>
          <div className="notice-date">1-14.11.2024</div>
          
          <div className="menu-section">
            <h2 className="section-title">Special Menu - Only 12€!</h2>
            <ul className="menu-list">
              <li>Palak Paneer</li>
              <li>Butter Chicken</li>
              <li>Lamb Chilli</li>
            </ul>
          </div>
          
          <div className="menu-section">
            <h2 className="section-title">Beverages</h2>
            <ul className="menu-list">
              <li>Mango Lassi - 4€</li>
              <li>Nepalilainen olut Gukha 330ml (4.8%) -5.5€</li>
              <li>Hana olut 500ml (4.8%) -6€</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RestaurantNotice;