import React from 'react'
import './Footer.css';
import { images } from '../../constants';

import { IoLocationSharp } from "react-icons/io5";
import { FaFacebook, FaPhoneAlt, FaTiktok } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { SlCalender } from "react-icons/sl";
import { useDispatch, useSelector } from 'react-redux';

const Footer = () => {
  const inEnglish = useSelector(state => state.inEnglish)

  return (
    <footer class="footer">
      <div class="waves">
        <div class="wave" id="wave1"></div>
        <div class="wave" id="wave2"></div>
        <div class="wave" id="wave3"></div>
        <div class="wave" id="wave4"></div>
      </div>
      <div class="row">
        <div class="col app__footer-logo">
          <ul>
            <li> <div class="logo"><a href="#"><img src={images.logo_footer} /></a></div></li>
            <li>
              <p className='app__footer-title'>
                {inEnglish?
                  "Check out our versatile menu and book your table today to ensure an unforgettable dining experience! Akhanda"
                  : "Tutustu monipuoliseen ruokalistaamme ja varaa pöytäsi jo tänään unohtumattoman ruokaelämyksen varmistamiseksi! Akhanda"
                  }
              </p>
            </li>

          </ul>

        </div>
        <div className='app__footer-break'></div>
        <div class="col">
          <div className='app__footer-links'>
            <div className='app__footer-information'>
              <IoLocationSharp size={40} color='white' />
              <div className='app__footer_contact-epa'>
                <p style={{ color: 'white' }}>{inEnglish?"Find Us":"Löydä Meidät"}</p>
                <a target='_blank' href='https://www.google.com/maps/dir//Valimotie+2,+00380+Helsinki/@60.5485927,24.4204927,9z/data=!4m9!4m8!1m0!1m5!1m1!1s0x468df61b97470553:0xcce615549955e40d!2m2!1d24.8758813!2d60.2157276!3e3?entry=ttu' style={{ color: 'black' }}> Valimotie 2, 00380 HELSINKI</a>
              </div>
            </div>

            <div className='app__footer-information'>
              <FaPhoneAlt size={40} color='white' />
              <div className='app__footer_contact-epa'>
                <p style={{ color: 'white' }}>{inEnglish?"Call US":"Soita Meille"}</p>
                <p style={{ color: 'black' }}>0400225222</p>
              </div>
            </div>
            <div className='app__footer-information'>
              <MdEmail size={40} color='white' />
              <div className='app__footer_contact-epa'>
                <p>{inEnglish?"Send Email":"Lähetä Sähköpostia"}</p>
                <p style={{ color: 'black' }}>akhandaoy@gmail.com</p>
              </div>
            </div>
          </div>
        </div>
        <div className='app__footer-break'></div>
        <div class="col app_footer-aukioloajat">
          <div className='app__footer-timetable'>
            <SlCalender className='calender' size={30} />
            <p>{inEnglish?"Opening Hours":"AUKIOLOAJAT"}</p>
          </div>
          <ul className='weekdays-ul'>
            <li>Ma-Pe: 10:30-21:00</li>
            <li>La:12:00-21:00</li>
            <li>Su:12:00-21:00</li>
          </ul>
        </div>
        <div className='app__footer-break'></div>
        <div class="col">
          <ul class="social-li">

            <li>
              <FaFacebook size={25} href='https://www.facebook.com/profile.php?id=61557038018234' target='_blank' />

            </li>


            <li>
              <FaTiktok size={25} href='https://www.tiktok.com/@akhanda.nepalilai' target='_blank' />

            </li>




          </ul>
        </div>
      </div>
      <p>&copy; 2023|| Kaikki oikeudet pidätetään Akhanda Ravintolan toimesta.</p>
    </footer>

  )
}

export default Footer





